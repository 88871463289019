import { Component, Mixins, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
// @ts-ignore
import UlEmailField, { ConfirmableMail } from "@/components/UlEmailField.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import {
  GetHolidayDate,
  GetHolidayDayweek,
  GetMenu
} from "@/api/booking-setting/response";
import { GetRequest, RegisterRequest } from "@/api/booking-setting/request";
import BookingSettingGetAPI from "@/store/booking-setting/get";
import BookingSettingRegisterAPI from "@/store/booking-setting/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UlEmailField,
    Draggable
  }
})
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "予約";
  headingSub = "Booking";
  breadCrumbs = [
    { text: "予約", disabled: true },
    { text: "店舗一覧", disabled: false, to: { name: "booking" } },
    { text: "編集", disabled: true }
  ];

  // メニューのプレースホルダー
  menuPlaceholders = [
    "席のみ予約",
    "ベジタブルコース　6,480円",
    "ベジタブルコース　チケット利用　4,320円",
    "スペシャルファーマーズコース　7,000円",
    "カジュアルコース 3,780円",
    "貸切予約"
  ];

  // 最大・最小人数のselect
  customers = [...Array(10).keys()].map(n => {
    return { text: `${n + 1}人`, value: n + 1 };
  });

  // 定休曜日のselect
  dayOfWeek = [
    { text: "月曜日", value: 1 },
    { text: "火曜日", value: 2 },
    { text: "水曜日", value: 3 },
    { text: "木曜日", value: 4 },
    { text: "金曜日", value: 5 },
    { text: "土曜日", value: 6 },
    { text: "日曜日", value: 0 }
  ];

  // 予約受付時間（時）のselect
  hours = [...Array(24).keys()].map(n => {
    const val = ("00" + n).slice(-2);
    return { text: val, value: val };
  });

  // 予約受付時間（分）のselect
  minutes = [...Array(12).keys()].map(n => {
    const val = ("00" + n * 5).slice(-2);
    return { text: val, value: val };
  });

  // 予約受付時間間隔のselect
  intervalTime = [...Array(13).keys()].map(n => {
    const val = ("00" + n * 5).slice(-2);
    return { text: val, value: n * 5 };
  });

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // DatePickerの表示フラグ
  modal = null;

  // 入力データ
  inputData = {
    id: null as null | number,
    shopId: Number(this.shopId),
    isAvailable: "0",
    isAttentionForTemporaryBooking: false,
    isMenu: false,
    isNumber: false,
    isHolidayDayweek: false,
    isHolidayDate: false,
    isAcceptTime: false,
    isAcceptTimeInterval: false,
    isLastAcceptTime: false,
    isAttention: false,
    mails: [] as ConfirmableMail[],
    attentionForTemporaryBooking: null as string | null,
    menuTitle: null as string | null,
    menus: [] as GetMenu[],
    numberMin: null as number | null,
    numberMax: null as number | null,
    holidayDayweeks: [] as GetHolidayDayweek[],
    holidayDates: [] as GetHolidayDate[],
    acceptStartTimeHour: null as string | null,
    acceptStartTimeMin: null as string | null,
    acceptEndTimeHour: null as string | null,
    acceptEndTimeMin: null as string | null,
    acceptTimeInterval: null as number | null,
    lastAcceptTime: null as number | null,
    attention: null as string | null,
    mailForUser: null as string | null,
    phoneForUser: null as string | null
  };

  get loading() {
    return Loading.isLoading;
  }

  /**
   * 予約機能を使用するか否かのフラグ
   */
  get isShowDetail() {
    return this.inputData.isAvailable === "1";
  }

  /**
   * 定休曜日のゲッター
   */
  get holidayDayweeks() {
    return this.inputData.holidayDayweeks.map(h => h.dayweek);
  }

  /**
   * 定休曜日のセッター
   *
   * @param dayweeks 定休曜日の配列
   */
  set holidayDayweeks(dayweeks: number[]) {
    const newValues = [] as GetHolidayDayweek[];
    for (let i = 0; i < dayweeks.length; i++) {
      const d = this.inputData.holidayDayweeks.filter(
        h => h.dayweek === dayweeks[i]
      );
      if (d.length > 0) {
        newValues.push({ id: d[0].id, dayweek: d[0].dayweek });
      } else {
        newValues.push({ id: null, dayweek: dayweeks[i] });
      }
    }
    this.inputData.holidayDayweeks = newValues;
  }

  /**
   * 定休日のゲッター
   */
  get holidayDates() {
    return this.inputData.holidayDates.map(h =>
      Edit.replaceSlashToHyphen(h.date)
    );
  }

  /**
   * 定休日のセッター
   *
   * @param dates 定休日の配列
   */
  set holidayDates(dates: string[]) {
    const newValues = [] as GetHolidayDate[];
    for (let i = 0; i < dates.length; i++) {
      const d = this.inputData.holidayDates.filter(h => h.date === dates[i]);
      if (d.length > 0) {
        newValues.push({ id: d[0].id, date: d[0].date });
      } else {
        newValues.push({ id: null, date: dates[i] });
      }
    }
    this.inputData.holidayDates = newValues;
  }

  /**
   * フォーマットされた日付のゲッター
   */
  get selectedDates() {
    if (this.inputData.holidayDates.length <= 0) {
      return [];
    }
    return this.inputData.holidayDates.map(d =>
      Edit.replaceHyphenToSlash(d.date)
    );
  }

  get shopName() {
    return this.$route.params.shopName || "";
  }

  /**
   * フォーマットされた日付のセッター
   *
   * @param values 変更後の日付の配列
   */
  set selectedDates(values: string[]) {
    if (!values || values.length <= 0) {
      this.inputData.holidayDates = [];
      return;
    }
    this.holidayDates = values.map(v => Edit.replaceSlashToHyphen(v));
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.get();
    this.syncParams();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await BookingSettingGetAPI.clear();
    await BookingSettingRegisterAPI.clear();
  }

  /**
   * メニューの追加ボタンが押された際のコールバック
   *
   * @param index 選択されたメニューのインデックス
   */
  addMenuClickCallback(index: number) {
    this.inputData.menus.splice(index + 1, 0, { name: "", id: null });
  }

  /**
   * メニューの削除ボタンが押された際のコールバック
   *
   * @param index 選択されたメニューアドレスのインデックス
   */
  removeMenuClickCallback(index: number) {
    this.inputData.menus.splice(index, 1);
    if (this.inputData.menus.length <= 0) {
      this.inputData.menus.push({ name: "", id: null });
    }
  }

  /**
   * メニューのプレースホルダーに表示するテキストを取得する
   *
   * @param index メニューのインデックス
   */
  getMenuPlaceholder(index: number) {
    if (index < this.menuPlaceholders.length) {
      return this.menuPlaceholders[index];
    }
    return "";
  }

  /**
   * 予約情報の取得処理
   */
  async get(): Promise<boolean> {
    if (BookingSettingGetAPI.isSuccess) {
      return true;
    }

    const request = this.createGetRequest();
    await BookingSettingGetAPI.get(request);
    if (!BookingSettingGetAPI.isSuccess) {
      await Flash.setErrorNow({
        message: BookingSettingGetAPI.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    return BookingSettingGetAPI.isSuccess;
  }

  /**
   * プレビュー
   */
  async preview() {
    await this.callRegisterApi(1);
    if (BookingSettingRegisterAPI.isSuccess) {
      WindowOpen.preview(BookingSettingRegisterAPI.getPreviewUrl);
    }
  }

  /**
   * 更新する
   */
  async register() {
    await this.callRegisterApi(0);
    if (BookingSettingRegisterAPI.isSuccess) {
      await this.redirectWithSuccessAlert(
        "予約基本情報を保存しました。",
        "/booking"
      );
    }
  }

  async callRegisterApi(isPreview: number) {
    await Flash.clear();
    if (this.isLoading) {
      return true;
    }

    const request = this.createRegisterRequest(isPreview);
    await BookingSettingRegisterAPI.register(request);
    if (!BookingSettingRegisterAPI.isSuccess) {
      await Flash.setErrorNow({
        message: BookingSettingRegisterAPI.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * dataから予約情報取得用のリクエストを作成する
   */
  private createGetRequest() {
    return { id: this.shopId } as GetRequest;
  }

  /**
   * dataから登録更新用のリクエストを作成する
   */
  private createRegisterRequest(isPreview: number) {
    // 必須項目
    const request = {
      id: this.inputData.id,
      shopId: this.inputData.shopId,
      isAvailable: Number(this.inputData.isAvailable),
      isAttentionForTemporaryBooking: this.inputData
        .isAttentionForTemporaryBooking
        ? 1
        : 0,
      isMenu: this.inputData.isMenu ? 1 : 0,
      isNumber: this.inputData.isNumber ? 1 : 0,
      isHolidayDayweek: this.inputData.isHolidayDayweek ? 1 : 0,
      isHolidayDate: this.inputData.isHolidayDate ? 1 : 0,
      isAcceptTime: this.inputData.isAcceptTime ? 1 : 0,
      isAcceptTimeInterval: this.inputData.isAcceptTimeInterval ? 1 : 0,
      isLastAcceptTime: this.inputData.isLastAcceptTime ? 1 : 0,
      isAttention: this.inputData.isAttention ? 1 : 0,
      mailForUser: this.inputData.mailForUser,
      phoneForUser: this.inputData.phoneForUser,
      isPreview: isPreview,
      mailId: [] as (null | number)[],
      mailAddress: [] as string[],
      mailConfirm: [] as string[],
      menuId: [] as (null | number)[],
      menuName: [] as string[],
      menuSort: [] as number[],
      holidayDayweekId: [] as (null | number)[],
      holidayDayweek: [] as number[],
      holidayDateId: [] as (null | number)[],
      holidayDate: [] as string[]
    } as RegisterRequest;
    if (request.isAvailable === 0) {
      return request;
    }

    // 予約通知メールアドレス
    if (this.inputData.mails.length > 0) {
      for (let i = 0; i < this.inputData.mails.length; i++) {
        request.mailId.push(this.inputData.mails[i].id);
        request.mailAddress.push(this.inputData.mails[i].address);
        request.mailConfirm.push(this.inputData.mails[i].addressConfirm);
      }
    }

    // 仮予約の注意書き
    if (this.inputData.isAttentionForTemporaryBooking) {
      request.attentionForTemporaryBooking = this.inputData.attentionForTemporaryBooking;
    }

    // メニュー
    if (this.inputData.isMenu) {
      request.menuTitle = this.inputData.menuTitle;
      for (let i = 0; i < this.inputData.menus.length; i++) {
        request.menuId.push(this.inputData.menus[i].id);
        request.menuName.push(this.inputData.menus[i].name);
        request.menuSort.push(i + 1);
      }
    }

    // 予約人数
    if (this.inputData.isNumber) {
      request.numberMin = this.inputData.numberMin;
      request.numberMax = this.inputData.numberMax;
    }

    // 定休曜日
    if (this.inputData.isHolidayDayweek) {
      for (let i = 0; i < this.inputData.holidayDayweeks.length; i++) {
        request.holidayDayweekId.push(this.inputData.holidayDayweeks[i].id);
        request.holidayDayweek.push(this.inputData.holidayDayweeks[i].dayweek);
      }
    }

    // 定休日
    if (this.inputData.isHolidayDate) {
      for (let i = 0; i < this.inputData.holidayDates.length; i++) {
        request.holidayDateId.push(this.inputData.holidayDates[i].id);
        request.holidayDate.push(this.inputData.holidayDates[i].date);
      }
    }

    // 予約受付時間
    if (this.inputData.isAcceptTime) {
      request.acceptStartTimeHour = this.inputData.acceptStartTimeHour;
      request.acceptStartTimeMin = this.inputData.acceptStartTimeMin;
      request.acceptEndTimeHour = this.inputData.acceptEndTimeHour;
      request.acceptEndTimeMin = this.inputData.acceptEndTimeMin;
    }

    // 予約受付時間間隔
    if (this.inputData.isAcceptTimeInterval) {
      request.acceptTimeInterval = this.inputData.acceptTimeInterval;
    }

    // 直前の予約受付制限時間
    if (this.inputData.isLastAcceptTime) {
      request.lastAcceptTime = this.inputData.lastAcceptTime;
    }

    // 予約の際の注意事項
    if (this.inputData.isAttention) {
      request.attention = this.inputData.attention;
    }
    return request;
  }

  /**
   * APIのレスポンスをdataにsyncする
   */
  private syncParams() {
    this.inputData.id = BookingSettingGetAPI.getId;
    this.inputData.isAvailable = String(BookingSettingGetAPI.getIsAvailable);
    this.inputData.isAttentionForTemporaryBooking =
      BookingSettingGetAPI.getIsAttentionForTemporaryBooking === 1;
    this.inputData.isMenu = BookingSettingGetAPI.getIsMenu === 1;
    this.inputData.isNumber = BookingSettingGetAPI.getIsNumber === 1;
    this.inputData.isHolidayDayweek =
      BookingSettingGetAPI.getIsHolidayDayweek === 1;
    this.inputData.isHolidayDate = BookingSettingGetAPI.getIsHolidayDate === 1;
    this.inputData.isAcceptTime = BookingSettingGetAPI.getIsAcceptTime === 1;
    this.inputData.isAcceptTimeInterval =
      BookingSettingGetAPI.getIsAcceptTimeInterval === 1;
    this.inputData.isLastAcceptTime =
      BookingSettingGetAPI.getIsLastAcceptTime === 1;
    this.inputData.isAttention = BookingSettingGetAPI.getIsAttention === 1;
    this.inputData.attentionForTemporaryBooking =
      BookingSettingGetAPI.getAttendionForTemporaryBooking;
    this.inputData.menuTitle = BookingSettingGetAPI.getMenuTitle;
    this.inputData.numberMin = BookingSettingGetAPI.getNumberMin;
    this.inputData.numberMax = BookingSettingGetAPI.getNumberMax;
    this.inputData.acceptStartTimeHour =
      BookingSettingGetAPI.getAcceptStartTimeHour;
    this.inputData.acceptStartTimeMin =
      BookingSettingGetAPI.getAcceptStartTimeMin;
    this.inputData.acceptEndTimeHour =
      BookingSettingGetAPI.getAcceptEndTimeHour;
    this.inputData.acceptEndTimeMin = BookingSettingGetAPI.getAcceptEndTimeMin;
    this.inputData.acceptTimeInterval =
      BookingSettingGetAPI.getAcceptTimeInterval;
    this.inputData.lastAcceptTime = BookingSettingGetAPI.getLastAcceptTime;
    this.inputData.attention = BookingSettingGetAPI.getAttention;
    this.inputData.mailForUser = BookingSettingGetAPI.getMailForUser;
    this.inputData.phoneForUser = BookingSettingGetAPI.getPhoneForUser;
    this.inputData.mails = BookingSettingGetAPI.getMails.map(m => {
      return {
        id: m.id,
        address: m.address,
        addressConfirm: m.address,
        dispNum: null
      };
    });
    if (this.inputData.mails.length <= 0) {
      this.inputData.mails.push({
        id: null,
        address: "",
        addressConfirm: "",
        dispNum: null
      });
    }
    this.inputData.menus = BookingSettingGetAPI.getMenus;
    if (this.inputData.menus.length <= 0) {
      this.inputData.menus.push({
        id: null,
        name: ""
      });
    }
    this.inputData.holidayDayweeks = BookingSettingGetAPI.getHolidayDayweeks;
    this.inputData.holidayDates = BookingSettingGetAPI.getHolidayDates;
  }

  /**
   * 日付のdividerを-から/に変える
   *
   * @param date 日付の文字列
   */
  static replaceHyphenToSlash(date: string) {
    return date.replace(/-/g, "/");
  }

  /**
   * 日付のdividerを/から-に変える
   *
   * @param date 日付の文字列
   */
  static replaceSlashToHyphen(date: string) {
    return date.replace(/\//g, "-");
  }
}
