import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { RegisterRequest } from "@/api/booking-setting/request";
import * as BookingSettingAPI from "@/api/booking-setting";
import { isSuccess } from "@/api/response";
import { RegisterResponse } from "@/api/booking-setting/response";

const MODULE_NAME = "booking-setting/register";

/**
 * 予約情報取得API（/regist-booking-setting）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
  // state
  registerResponse: RegisterResponse = {} as RegisterResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  get getPreviewUrl() {
    if (this.registerResponse.results) {
      return this.registerResponse.results.previewUrl;
    } else {
      return "";
    }
  }

  // MutationActions
  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const registerResponse = await BookingSettingAPI.register(registerRequest);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Register);
