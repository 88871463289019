
























































import { Component, PropSync, Vue } from "vue-property-decorator";

/**
 * メールアドレスと入力確認
 */
export interface ConfirmableMail {
  id: number | null;
  address: string;
  addressConfirm: string;
  dispNum: number | null;
}

@Component
export default class UlEmailField extends Vue {
  @PropSync("mails")
  syncedMails!: ConfirmableMail[];

  /**
   * 入力されたメールアドレスのバリデーションエラーメッセージ
   */
  confirmErrorMessage(mail: ConfirmableMail) {
    if (mail.address === "") {
      return null;
    }
    return mail.address === mail.addressConfirm
      ? null
      : "メールアドレスが一致しません";
  }

  /**
   * メールアドレスの追加ボタンを表示するか否か
   *
   * @param index 選択されたメールアドレスのインデックス
   */
  isShowAddEmailButton(index: number) {
    const MailsLength = this.syncedMails.length;
    return this.syncedMails.length === index + 1 && MailsLength < 10;
  }

  /**
   * メールアドレスの追加ボタンが押された際のコールバック
   */
  addEmailClickCallback() {
    this.syncedMails.push({
      id: null,
      address: "",
      addressConfirm: "",
      dispNum: this.syncedMails.length + 1
    });
  }

  /**
   * メールアドレスの削除ボタンが押された際のコールバック
   *
   * @param index 選択されたメールアドレスのインデックス
   */
  removeEmailClickCallback(index: number) {
    this.syncedMails.splice(index, 1);
    if (this.syncedMails.length <= 0) {
      // 配列が空になった場合は未入力のオブジェクトをpushする
      this.addEmailClickCallback();
    }
  }
}
